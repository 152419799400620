import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button, Checkbox, List, Input, Form, Row, Col, Card, message, Select, Space, Divider, Modal, InputNumber, Skeleton, Switch } from 'antd';
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../../axiosInterceptor";
import camuflado from "../../../fundo.webp";
import UploadImg from "../../../UploadImg";
import { faPlus, faRobot, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clientePadraoApi from "../../../clientePadraoApi";
import botApiUrl from "../../../botApiUrl";
import TabelaBloqueio from "../mensagens/TabelaBloqueio";
import ScheduleEditor from "./ScheduleEditor";


const Configuracoes = ({recarregarConfiguracoes}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);

  const [messageContent, setMessageContent] = useState('');
  const [idLoja, setIDLoja] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [instancias, setInstancias] = useState([]);
  const [telefones, setTelefones] = useState([]);


  const [mensagem, setMensagem] = useState('');
  const [telefonePrincipal, setTelefonePrincipal] = useState('');
  const [clientIdPrincipal, setClientIdPrincipal] = useState('');

  const [textoEditado, setTextoEditado] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [arrayBots, setArrayBots] = useState([]);


  const [selectedBotType, setSelectedBotType] = useState(null);
  const [selectedBot, setSelectedBot] = useState(null);
  const [selectedBotID, setSelectedBotID] = useState(null);
  const [selectInstanciaPrincipal, setSelectInstanciaPrincipal] = useState('');
  const [instanciaPrincipal, setInstanciaPrincipal] = useState(null);
  const [arrayContatos, setArrayContatos] = useState([]);



  const [formPrimeiraMensagem] = Form.useForm();
  const [formSegundaMensagem] = Form.useForm();
  const [formTerceiraMensagem] = Form.useForm();
  const [formMensagemDeAusencia] = Form.useForm();
  const [formMensagemDeAusenciaDoDia] = Form.useForm();
  const [formPalavraChave1] = Form.useForm();
  const [formPalavraChave2] = Form.useForm();
  const [formPalavraChave3] = Form.useForm();
  const [formPalavraChave4] = Form.useForm();
  const [formPalavraChave5] = Form.useForm();

  const [fileListMensagem1, setFileListMensagem1] = useState([]);
  const [fileListMensagem2, setFileListMensagem2] = useState([]);
  const [fileListMensagem3, setFileListMensagem3] = useState([]);
  const [fileListMensagemDeAusencia, setFileListMensagemDeAusencia] = useState([]);
  const [fileListMensagemDeAusenciaDoDia, setFileListMensagemDeAusenciaDoDia] = useState([]);

  const [fileListPalavraChave1, setFileListPalavraChave1] = useState([]);
  const [fileListPalavraChave2, setFileListPalavraChave2] = useState([]);
  const [fileListPalavraChave3, setFileListPalavraChave3] = useState([]);
  const [fileListPalavraChave4, setFileListPalavraChave4] = useState([]);
  const [fileListPalavraChave5, setFileListPalavraChave5] = useState([]);
  const [pausaDoBot,setPausaDoBot] = useState('');
  const [horariosBot,setHorariosBot] = useState("{\"segundaFeira\":[],\"tercaFeira\":[],\"quartaFeira\":[],\"quintaFeira\":[{\"inicio\":\"08:00\",\"fim\":\"09:00\"},{\"inicio\":\"10:00\",\"fim\":\"11:00\"},{\"inicio\":\"13:00\",\"fim\":\"14:00\"}],\"sextaFeira\":[],\"sabado\":[],\"domingo\":[]}");




 // const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [visible2, setVisible2] = useState(false);


  const resetAllForms = async () => {
    formPrimeiraMensagem.resetFields();
    formSegundaMensagem.resetFields();
    formTerceiraMensagem.resetFields();
    formMensagemDeAusencia.resetFields();
    formMensagemDeAusenciaDoDia.resetFields();
    formPalavraChave1.resetFields();
    formPalavraChave2.resetFields();
    formPalavraChave3.resetFields();
    formPalavraChave4.resetFields();
    formPalavraChave5.resetFields();
    setFileListMensagem1([]);
    setFileListMensagem2([]);
    setFileListMensagem3([]);
    setFileListPalavraChave1([]);
    setFileListPalavraChave2([]);
    setFileListPalavraChave3([]);
    setFileListPalavraChave4([]);
    setFileListPalavraChave5([]);
  };
  



  const [isActive, setIsActive] = useState(false);

  const [palavraChave1, setPalavraChave1] = useState([]);
  const [palavraChave2, setPalavraChave2] = useState([]);
  const [palavraChave3, setPalavraChave3] = useState([]);
  const [palavraChave4, setPalavraChave4] = useState([]);
  const [palavraChave5, setPalavraChave5] = useState([]);
  const [telefonesSelecionados, setTelefonesSelecionados] = useState([]);
  const [recarregarSelectBots, setRecarregarSelectBots] = useState(false);
  


  const { Option } = Select;





  const getRequestOptions = (token) => ({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  useEffect(() => {
    if (idUser && accessToken) {
      setLoading(true);
      clientePadraoApi
        .get(`/v1/bot/${idUser}`, getRequestOptions(accessToken))
        .then((response) => {
          setArrayBots(response.data);
        })
        .catch((error) => console.error("Erro ao buscar bots:", error))
        .finally(() => setLoading(false));
    }
  }, [idUser, recarregarConfiguracoes, accessToken]);


  useEffect(() => {
    if (idLoja && accessToken) {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      botApiUrl.get(
        `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
        options
      ).then((resp) => {
        if (resp.data.length > 0) {
          setInstancias(resp.data);
          for (const instancia of resp.data) {
            if (instancia.principal) {
              formConfiguracoesDoBot.setFieldValue('linkApi', instancia.linkApi);
              formConfiguracoesDoBot.setFieldValue('accessTokenCustom', instancia.accessTokenCustom);
              setSelectInstanciaPrincipal(instancia.id);
              setSelectedBotType(instancia.acao);
            }
          }
        }

        setLoading(false);
      }).catch((error) => {
        setLoading(false);
      });
    }

  }, [accessToken, idLoja, recarregarConfiguracoes]);

  useEffect(() => {
    try {
      if (idLoja && accessToken) {
        console.log('Buscando lista de bots');
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        clientePadraoApi.get(
          `/v1/bot/${idLoja}`,
          options
        ).then((resp) => {
          setArrayBots(resp.data);
          setLoading(false);
          setSelectedBotID(null);
          setSelectedBot(null);
        }).catch((error) => {
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
    }


  }, [accessToken, idLoja, recarregarSelectBots, recarregarConfiguracoes]);






  const base64ToFile = (base64, filename, mimeType) => {
    console.log(base64);
    const arr = base64.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mimeType });
  };


  useEffect(() => {
    if (!idUser) return;

    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        try {
          const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
          setIDLoja(resp.data.id);

        } catch (error) {
        }

   
      }
    };
    fetchData();
  }, [idUser, recarregarConfiguracoes]);


  useEffect(() => {
    fetchTags(); // Chama fetchTags ao montar o componente
  }, [idLoja]); // Dependências do efeito

  const handleTextAreaChange = (event) => {
    //setMessageContent(event.target.value);
    console.log(event.target.value);
    setTextoEditado(true);
  };
  const handleSelectedBotType = (tipo) => {
    setSelectedBotType(tipo);
    console.log(tipo);

  };
  const [form] = Form.useForm();


  const handleSwitchChange = (checked) => {
    setIsActive(checked);
    console.log("Switch value:", checked ? "Ativo" : "Inativo");
  };


  const handleSelectInstanciaPrincipal = (instancia) => {
    setSelectInstanciaPrincipal(instancia);
    console.log(instancia);
  };


  const limparMensagem = async (mensagem) => {
    Modal.confirm({
      title: "Tem certeza?",
      content: "Deseja realmente limpar a mensagem?",
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };
          
          const response = await clientePadraoApi.get(`/v1/bot/limparMensagem/${selectedBotID}/${mensagem}`, options);
          
          if (response.status === 204) { 
            message.success('Mensagem Limpa!');
            if(mensagem == 'mensagem1'){formPrimeiraMensagem.resetFields(); setFileListMensagem1([]);}
            if(mensagem == 'mensagem2'){formSegundaMensagem.resetFields(); setFileListMensagem2([]);}
            if(mensagem == 'mensagem3'){formTerceiraMensagem.resetFields(); setFileListMensagem3([]);}
            if(mensagem == 'mensagemDeAusencia'){formMensagemDeAusencia.resetFields(); setFileListMensagemDeAusencia([]);}
            if(mensagem == 'mensagemDeAusenciaDoDia'){formMensagemDeAusenciaDoDia.resetFields(); setFileListMensagemDeAusenciaDoDia([]);}
            if(mensagem == 'palavra1'){formPalavraChave1.resetFields(); setFileListPalavraChave1([]);}
            if(mensagem == 'palavra2'){formPalavraChave2.resetFields(); setFileListPalavraChave2([]);}
            if(mensagem == 'palavra3'){formPalavraChave3.resetFields(); setFileListPalavraChave3([]);}
            if(mensagem == 'palavra4'){formPalavraChave4.resetFields(); setFileListPalavraChave4([]);}
            if(mensagem == 'palavra5'){formPalavraChave5.resetFields(); setFileListPalavraChave5([]);}
          } else {
            message.error('Falha ao limpar a mensagem.');
          }
          
          console.log(response);
        } catch (error) {
          console.error("Erro ao limpar a mensagem:", error);
          message.error('Erro ao limpar a mensagem. Tente novamente.');
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        message.info('Ação de limpar mensagem cancelada.');
      }
    });
  };
  

  

  const handleSelectedBotChange = async (bot) => {
    try {
      await resetAllForms();
      setLoading(true);
      setSelectedBotID(bot);
     


      const botS = arrayBots.find(botAux => botAux.id === bot);

      console.log(botS); // Retorna o primeiro objeto que tem id 2
      setIsActive(botS.ativo);


      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      const resp = await clientePadraoApi.get(`/v1/bot/buscarPorID/${bot}`, options);
      const resp2 = await clientePadraoApi.get(`/v1/telefones/buscarPorIdBot/${bot}`, options);
  
  
      if (instancias.length > 0) {
        const telefonesAux = [];
        for (const telefone of instancias) {
          const validaTelefoneEmUso = await clientePadraoApi.get(`/v1/telefones/verificarTelefone/${telefone.tel}`, options);
          console.log(bot);
          console.log(validaTelefoneEmUso);
          telefonesAux.push({
            label: formatarTelefone(telefone.tel),
            value: telefone.tel,
            disabled: bot == validaTelefoneEmUso.data || validaTelefoneEmUso.data == 'Telefone não pertence a nenhum bot.' ? false : true,
          });
        }
        setTelefones(telefonesAux);
  
      }
  
      if (resp2.data.length > 0) {
        const telefonesAux = [];
        for (const telefone of resp2.data) {
          telefonesAux.push(telefone.telefone);
        }
        console.log(telefonesAux);
        setTelefonesSelecionados(telefonesAux);
      } else {
        setTelefonesSelecionados([]);
      }
      setPausaDoBot(resp.data.expiracaoTempo);
      form.setFieldValue('pausadoBot',resp.data.expiracaoTempo);
      console.log(resp.data.expiracaoTempo);
      setSelectedBot(resp.data);
  
      if (resp.data.mensagemPadraoTechsbot1) {
        formPrimeiraMensagem.setFieldsValue({ mensagemPadraoTechsbot1: JSON.parse(resp.data.mensagemPadraoTechsbot1).mensagem });
        const arrayImagensString = JSON.parse(resp.data.mensagemPadraoTechsbot1).imagem;
        console.log(arrayImagensString);
        if (arrayImagensString.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensString[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListMensagem1(initialFileList);
        }
      }
  
      if (resp.data.mensagemPadraoTechsbot2) {
        formSegundaMensagem.setFieldsValue({ mensagemPadraoTechsbot2: JSON.parse(resp.data.mensagemPadraoTechsbot2).mensagem });
        const arrayImagensString2 = JSON.parse(resp.data.mensagemPadraoTechsbot2).imagem;
        console.log(arrayImagensString2);
        if (arrayImagensString2.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensString2[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListMensagem2(initialFileList);
        }
      }
  
      if (resp.data.mensagemPadraoTechsbot3) {
        formTerceiraMensagem.setFieldsValue({ mensagemPadraoTechsbot3: JSON.parse(resp.data.mensagemPadraoTechsbot3).mensagem });
        const arrayImagensString3 = JSON.parse(resp.data.mensagemPadraoTechsbot3).imagem;
        console.log(arrayImagensString3);
        if (arrayImagensString3.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensString3[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListMensagem3(initialFileList);
        }
      }

      if (resp.data.mensagemDeAusencia) {
        formMensagemDeAusencia.setFieldsValue({ mensagemDeAusencia: JSON.parse(resp.data.mensagemDeAusencia).mensagem });
        const arrayImagensStringAusencia = JSON.parse(resp.data.mensagemDeAusencia).imagem;
        if (arrayImagensStringAusencia.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringAusencia[0]);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListMensagemDeAusencia(initialFileList);
        }
      }
      
  
      if (resp.data.mensagemDeAusenciaDoDia) {
        formMensagemDeAusenciaDoDia.setFieldsValue({ mensagemDeAusenciaDoDia: JSON.parse(resp.data.mensagemDeAusenciaDoDia).mensagem });
        const arrayImagensStringAusenciaDoDia = JSON.parse(resp.data.mensagemDeAusenciaDoDia).imagem;
        if (arrayImagensStringAusenciaDoDia.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringAusenciaDoDia[0]);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListMensagemDeAusenciaDoDia(initialFileList);
        }
      }




      if (resp.data.mensagemPadraoTechsBotPalavraChave1) {
        formPalavraChave1.setFieldsValue({ mensagemPadraoTechsBotPalavraChave1: JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave1).mensagem });
        formPalavraChave1.setFieldsValue({ palavraChave1: resp.data.palavraChave1 });
  
        const arrayImagensStringPalavraChave1 = JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave1).imagem;
        console.log(arrayImagensStringPalavraChave1);
        if (arrayImagensStringPalavraChave1.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringPalavraChave1[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListPalavraChave1(initialFileList);
        }
      }
      if (resp.data.mensagemPadraoTechsBotPalavraChave2) {
        formPalavraChave2.setFieldsValue({ mensagemPadraoTechsBotPalavraChave2: JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave2).mensagem });
        formPalavraChave2.setFieldsValue({ palavraChave2: resp.data.palavraChave2 });
  
        const arrayImagensStringPalavraChave2 = JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave2).imagem;
        console.log(arrayImagensStringPalavraChave2);
        if (arrayImagensStringPalavraChave2.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringPalavraChave2[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListPalavraChave2(initialFileList);
        }
      }

















      if (resp.data.mensagemPadraoTechsBotPalavraChave3) {
        formPalavraChave3.setFieldsValue({ mensagemPadraoTechsBotPalavraChave3: JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave3).mensagem });
        formPalavraChave3.setFieldsValue({ palavraChave3: resp.data.palavraChave3 });
  
        const arrayImagensStringPalavraChave3 = JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave3).imagem;
        console.log(arrayImagensStringPalavraChave3);
        if (arrayImagensStringPalavraChave3.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringPalavraChave3[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListPalavraChave3(initialFileList);
        }
      }



      
      if (resp.data.mensagemPadraoTechsBotPalavraChave4) {
        formPalavraChave4.setFieldsValue({ mensagemPadraoTechsBotPalavraChave4: JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave4).mensagem });
        formPalavraChave4.setFieldsValue({ palavraChave4: resp.data.palavraChave4 });
  
        const arrayImagensStringPalavraChave4 = JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave4).imagem;
        console.log(arrayImagensStringPalavraChave4);
        if (arrayImagensStringPalavraChave4.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringPalavraChave4[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListPalavraChave4(initialFileList);
        }
      }



      if (resp.data.mensagemPadraoTechsBotPalavraChave5) {
        formPalavraChave5.setFieldsValue({ mensagemPadraoTechsBotPalavraChave5: JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave5).mensagem });
        formPalavraChave5.setFieldsValue({ palavraChave5: resp.data.palavraChave5 });
  
        const arrayImagensStringPalavraChave5 = JSON.parse(resp.data.mensagemPadraoTechsBotPalavraChave5).imagem;
        console.log(arrayImagensStringPalavraChave5);
        if (arrayImagensStringPalavraChave5.length > 0) {
          const arrayImagens = JSON.parse(arrayImagensStringPalavraChave5[0]);
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileListPalavraChave5(initialFileList);
        }
      }


      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };


//********************************************TAGS***************************************************
const fetchTags = async () => {
  try {
    if (idLoja) { // Verifica se o idLoja existe, pois o código estava verificando !idLoja
      setLoading(true);

      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await botApiUrl.get(
        `/api/tags/loja/${idLoja}`,
        options
      );

      setTags(response.data); // Presumindo que o retorno contém um array de tags
    } else {
      console.warn("ID da loja não está definido.");
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Se for um 404, define tags como uma lista vazia
      setTags([]);
    } else {
      console.error("Erro ao carregar as tags:", error);
    }
  } finally {
    setLoading(false); // Garante que o loading é desativado mesmo em caso de erro
  }
};

const handleOk2 = async () => {
  try {
    const values = await form.validateFields();
    setLoading(true);

    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await botApiUrl.post(
      `/api/tags`,
      {
        nome: values.tag,
        loja: `${idLoja}`,
      },
      options
    );

    if (response.status === 201) {
      message.success("Tag adicionada com sucesso!");
      fetchTags(); // Recarrega a lista de tags
      form.resetFields(); // Reseta o formulário
    }
  } catch (error) {
    console.error("Erro ao salvar a tag:", error);
    message.error("Falha ao salvar a tag. Tente novamente."); // Exibe uma mensagem de erro para o usuário
  } finally {
    setLoading(false); // Assegura que o loading é desativado em caso de sucesso ou erro
  }
};

const handleDelete = async () => {
  try {
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    console.log("ID da tag selecionada para exclusão:", selectedTag);
    const response = await botApiUrl.delete(`/api/tags/${selectedTag}`, options);
    console.log("Resposta da API ao excluir tag:", response);

    // Verifica se o status é 204 (No Content)
    if (response.status === 204) {
      message.success('Tag excluída com sucesso!');
      fetchTags(); // Atualiza a lista de tags
      setVisible2(false); // Fecha o modal de confirmação após sucesso
    } else {
      console.error("Erro ao excluir tag, código de status:", response.status);
    }
  } catch (error) {
    console.error('Erro ao excluir a tag:', error);
  }
};
  //********************************************TAGS***************************************************




  const formatarTelefone = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
      if (numberWithoutCountryCode.length === 11) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };

  const tratarCheckbox = (checkbox) => {
    console.log(checkbox);

    setTelefonesSelecionados(checkbox);
  }


  // Modal Criar Bot

  const [visible, setVisible] = useState(false);
  const [form2] = Form.useForm();
  const [formConfiguracoesDoBot] = Form.useForm();


  const tratarHorariosBot = () => {
    var horarios = JSON.parse(horariosBot);
    console.log(horarios);
  };


  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    try {
      const values = await form2.validateFields();
      setLoading(true);
      // Enviar os dados para o backend
      console.log(values);
      // Simulação de requisição
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      const response = await clientePadraoApi
        .post(`/v1/bot`,
          {
            nome: values.nome,
            idLoja: `${idLoja}`,
            mensagemPadraoTechsbot1: "",
            mensagemPadraoTechsbot2: "",
            mensagemPadraoTechsbot3: "",
            expiracaoTempo: "",
            palavraChave1: "",
            palavraChave2: "",
            palavraChave3: "",
            palavraChave4: "",
            palavraChave5: "",
            mensagemPadraoTechsBotPalavraChave1: "",
            mensagemPadraoTechsBotPalavraChave2: "",
            mensagemPadraoTechsBotPalavraChave3: "",
            mensagemPadraoTechsBotPalavraChave4: "",
            mensagemPadraoTechsBotPalavraChave5: ""
          }, options);
          console.log(response);

          if(response.status == 201){
            console.log('dentro do if');
           await resetAllForms();
            setSelectedBotID(null);
            setSelectedBot(null);
            console.log('recarregarSelectBots');
            setRecarregarSelectBots(!recarregarSelectBots);
          }
          console.log('passei do if');
      //  onClose();
      setLoading(false);
      setVisible(false);

      /*setTimeout(() => {
      //  setLoading(false);
       // setVisible(false);
        form.resetFields();
      }, 2000);*/



    } catch (error) {
      setLoading(false);
      console.error('Validation Failed:', error);
    }
  };

  
  return (
    <Row
      style={{
        justifyContent: "center",
        marginTop: "110px",
        marginBottom: "80px",
        width:'100%'
      }}
    >

      <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
        <Card title="Configurações Gerais" bordered={false}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Form.Item
              label="Linha Principal"
            >
              <Select
                style={{ width: "200px" }}
                placeholder="Selecione uma linha..."
                value={selectInstanciaPrincipal}
                onChange={handleSelectInstanciaPrincipal}
                loading={loading}
              >
                {instancias.map((opcao) => (
                  <Option key={opcao.id} value={opcao.id} >
                    {formatarTelefone(opcao.tel)}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                  //  pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                }}
                //  disabled={arrayContatos.length == 0 ? true : false}
                loading={loading}
                onClick={async () => {
                  console.log(selectInstanciaPrincipal);


                  try {
                    setLoading(true);
                    const options = {
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                      },
                    };
                    const response1 = await botApiUrl.put(
                      `/v1/sessao/editarPrincipal/${selectInstanciaPrincipal}`,
                      // `https://deliverypdv.com.br/v1/sessao/editarPrincipal/${selectInstanciaPrincipal}`,
                      {}, options

                    );
                    setLoading(false);
                    console.log(response1);

                  } catch (e) {
                    console.log(e);
                    setLoading(false);
                  }
                }}
              >
                Salvar
              </Button>
            </Form.Item>
          </div>



          <div style={{ display: "flex", alignItems: "baseline", width: '100%' }}>
  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "10px" }}>
    <Card title="Gerenciador de Tags" bordered={false} style={{ marginTop: 20, textAlign: 'left', width: '100%' }}>
      <Form form={form} layout="inline" style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Form.Item
          name="tag"
          label="Adicionar Tag"
          rules={[{ required: true, message: 'Por favor, insira um nome de tag!' }]}
          style={{ flex: 1 }}
        >
          <Input placeholder="Digite o nome da tag" style={{ width: '100%' }} />
        </Form.Item>
        <Button
          style={{
            backgroundColor: 'black',
            borderColor: 'black',
            color: 'white',
          }}
          type="primary"
          onClick={handleOk2}
          loading={loading}
        >
          Salvar
        </Button>
      </Form>

      <div style={{ marginTop: 20, width: '100%' }}>
  <Row gutter={16}>
    {tags.map(tag => (
      <Col key={tag.id} xs={24} sm={12} md={8} lg={6} xl={6} style={{ marginBottom: 10 }}>
        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", width: '100%' }}>
          <span style={{
            backgroundColor: 'green',
            borderColor: 'green',
            color: 'white',
            padding: '5px',
            borderRadius: '5px',
            maxWidth: '80%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {tag.nome}
          </span>
          <Button 
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              color: 'white',
              marginLeft: 5, // Para um pequeno espaço entre o nome e o ícone
            }} 
            type="link" 
            onClick={() => {
              setSelectedTag(tag.id);
              setVisible2(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </Col>
    ))}
  </Row>
</div>

      <Modal
        title="Confirmação"
        visible={visible2}
        onOk={handleDelete}
        onCancel={() => setVisible2(false)}
        okText="Sim, Excluir"
        cancelText="Cancelar"
      >
        <p>Tem certeza que deseja excluir esta tag? <b>As ligações com contatos serão excluídas também!</b></p>
      </Modal>
    </Card>
  </Col>
</div>



   <TabelaBloqueio recarregarConfiguracoes={recarregarConfiguracoes}></TabelaBloqueio>
      
      
        </Card>
      </Col>


      <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
        <Card title="Configurações do Bot" bordered={false}>
          <Form layout="inline" style={{ display: "flex", alignItems: "baseline" }} form={formConfiguracoesDoBot} initialValues={instanciaPrincipal} onFinish={async (dados) => {
           
           try{
            console.log(dados);
            const options = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              },
            };
            setLoading(true);
            if (selectedBotType == 'Customizado') {
              const response1 = await botApiUrl.put(
                `/v1/sessao/editarAcao/${idLoja}`,
                {
                  acao: selectedBotType,
                  accessTokenCustom: dados.accessTokenCustom,
                  linkApi: dados.linkApi,
                }, options
              );
            } else {
              const response1 = await botApiUrl.put(
                `/v1/sessao/editarAcao/${idLoja}`,

                {
                  acao: selectedBotType,
                  accessTokenCustom: '',
                  linkApi: '',
                }, options
              );
            }
            setLoading(false);
           }catch (error) {
            setLoading(false);
  }

          }}>
            <Form.Item label="Tipo de Bot" >
              <Select
                style={{ width: "200px" }}
                placeholder="Selecione uma linha..."
                value={selectedBotType}
                onChange={handleSelectedBotType}
                loading={loading}
              >
                <Option key={'padrao'} value={'Default'}>{'       Padrão       '}</Option>
                <Option key={'menu'} value={'Menu'}>{'       Menu       '}</Option>
                <Option key={'custom'} value={'Customizado'}>{'       Custom       '}</Option>
              </Select>
            </Form.Item>
            {selectedBotType == "Customizado" ? <><Form.Item
              name="linkApi"
              label="Endereço"
            >
              <Input
                placeholder="Endereço"
                style={{ width: '100%' }}
              />
            </Form.Item>
              <Form.Item
                name="accessTokenCustom"
                label="Acess Token"
              >
                <Input
                  placeholder="Acess Token"
                  style={{ width: '100%' }}
                />
              </Form.Item></> : ''}

            <Form.Item>
              <Button type="primary" htmlType="submit"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                  //  pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                }}
                //  disabled={arrayContatos.length == 0 ? true : false}
                loading={loading}
              >
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>

      {selectedBotType == 'Default' ? <><Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>

        <Card title="Bot Padrão" bordered={false} >
          <Col style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
            <>
              <Button
                type="primary"
                htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                }}
                loading={loading}
                onClick={()=>{
                  showModal();
                  tratarHorariosBot();
                }}
              >
                Criar Bot <FontAwesomeIcon icon={faRobot} style={{ marginLeft: 8 }} />
              </Button>
              <Modal
                visible={visible}
                title="Criar Bot"
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancelar
                  </Button>,
                  <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Criar
                  </Button>,
                ]}
              >
                <Form form={form2} layout="vertical">
                  <Form.Item
                    name="nome"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, insira o nome' }]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </>

            <Button
              type="primary"
              htmlType="button"
              style={{
                backgroundColor: 'black',
                borderColor: 'black',
                color: 'white',
                //opacity: arrayContatos.length === 0 ? 0.5 : 1,
                //pointerEvents: arrayContatos.length === 0 ? 'none' : 'auto',
              }}
              onClick={() => {
                if(selectedBotID){
                  Modal.confirm({
                    title: 'Você tem certeza que deseja excluir o Bot?',
                    content: 'Esta ação não pode ser desfeita.',
                    okText: 'Sim, excluir',
                    okType: 'danger',
                    cancelText: 'Cancelar',
                    onOk() {
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                        data: arrayContatos
                      };
                      setLoading(true);
                      console.log(arrayContatos);
                try {
                  clientePadraoApi.delete(
                    `/v1/bot/${selectedBotID}`,
                    options
                  ).then((response) => {
                    console.log(response.status);
                    if (response.status === 201) {
                      message.success('Bot Excluído!');
                      console.log(selectedBotID);
                      console.log(idLoja);
                      resetAllForms();
                      setRecarregarSelectBots(!recarregarSelectBots);
                      setSelectedBotID(null);
                      setSelectedBot(null);
                    } else {
                      message.error('Falha ao Excluir o Bot!');
                    }
                  }).finally(() => {
                    setLoading(false);
                  });
                } catch (error) {
                  setLoading(false);
                }

                    },
                  });
                }
            
              }}
              // disabled={arrayContatos.length === 0}
              loading={loading}
            >
              Excluir Bot <FontAwesomeIcon icon={faRobot} style={{ marginLeft: 8 }} />
            </Button>

            <Form.Item>
              <Select
                style={{ width: "200px" }}
                placeholder="Selecione um Bot..."
                value={selectedBotID}
                onChange={handleSelectedBotChange}
                loading={loading}
              >

                {arrayBots.map((opcao) => (

                  <Option key={opcao.id} value={opcao.id} >
                    {opcao.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Card>
      </Col>

      {selectedBot != null ? <>  <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
          <Card title="Configurações Bot Padrão" bordered={false}>
            <Form layout="vertical" form={form} onFinish={async (dados) => {
              try {
                const options = {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  },
                };
                setLoading(true);
                const response = await clientePadraoApi
                  .post(`/v1/telefones/sincronizarTelefones/${selectedBotID}/${dados.pausadoBot}/${isActive}`,
                    telefonesSelecionados,
                    options);
                    console.log(response);
                    if(response.status == 201){
                      message.success('Configurações do Bot Salvas!');
                    }else{
                      message.error('Falha ao Salvar as Configurações!');
                    }
                setLoading(false);
              } catch (error) {
                setLoading(false);
              }
            }}>
              <Form.Item
                name="pausadoBot"
                label="Pausa do Bot em Minutos"
              >
                <InputNumber
                  placeholder="10"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Switch checkedChildren="Ativo" unCheckedChildren="Inativo"  checked={isActive} onChange={handleSwitchChange}/>
              {telefones.length > 0 && selectedBotID != null ? <div style={{ marginBottom: '20px' }}>
                <h2>Selecione os Telefones</h2> <Checkbox.Group options={telefones} value={telefonesSelecionados} onChange={tratarCheckbox} />     </div> : ''
              }
              <Form.Item>
                <Button type="primary" htmlType="submit"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                    // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                  }}
                  // disabled={arrayContatos.length == 0 ? true : false}
                  loading={loading}
                >
                  Salvar
                </Button>
              </Form.Item>
            </Form>




          </Card>
        </Col>


          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Primeira Interação" bordered={false}>
              <Form layout="vertical" form={formPrimeiraMensagem} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsbot1 != undefined) {
                  if (dados.mensagemPadraoTechsbot1.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListMensagem1);

                    fileListMensagem1.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsbot1,
                      imagem: [JSON.stringify(fileListMensagem1)]
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/mensagemPadraoTechsbot1`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                          setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item label="Primeira Interação" name="mensagemPadraoTechsbot1">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>

                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('mensagem1')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>

                </Form.Item>

                <UploadImg fileList={fileListMensagem1} setFileList={setFileListMensagem1} ></UploadImg>
              </Form>
            </Card>
          </Col>



          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Segunda Interação" bordered={false}>
              <Form layout="vertical" form={formSegundaMensagem} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsbot2 != undefined) {
                  if (dados.mensagemPadraoTechsbot2.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListMensagem2);

                    fileListMensagem2.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsbot2,
                      imagem: [JSON.stringify(fileListMensagem2)]
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/mensagemPadraoTechsbot2`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                           setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item label="Segunda Interação" name="mensagemPadraoTechsbot2">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>

                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('mensagem2')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListMensagem2} setFileList={setFileListMensagem2} ></UploadImg>
              </Form>
            </Card>
          </Col>



          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Terceira Interação" bordered={false}>
              <Form layout="vertical" form={formTerceiraMensagem} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsbot3 != undefined) {
                  if (dados.mensagemPadraoTechsbot3.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListMensagem3);

                    fileListMensagem3.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsbot3,
                      imagem: [JSON.stringify(fileListMensagem3)]
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/mensagemPadraoTechsbot3`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                           setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item label="Terceira Interação" name="mensagemPadraoTechsbot3">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('mensagem3')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListMensagem3} setFileList={setFileListMensagem3} ></UploadImg>
              </Form>
            </Card>
          </Col>





          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Palavra Chave 1" bordered={false}>
              <Form layout="vertical" form={formPalavraChave1} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsBotPalavraChave1 != undefined) {
                  if (dados.mensagemPadraoTechsBotPalavraChave1.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListPalavraChave1);

                    fileListPalavraChave1.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsBotPalavraChave1,
                      imagem: [JSON.stringify(fileListPalavraChave1)],
                      palavraChave: dados.palavraChave1
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      console.log(dados);

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/palavraChave1`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                           setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item
                  name="palavraChave1"
                >
                  <Input
                    placeholder="Palavra Chave 1"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Mensagem" name="mensagemPadraoTechsBotPalavraChave1">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>

                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('palavra1')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListPalavraChave1} setFileList={setFileListPalavraChave1} ></UploadImg>
              </Form>
            </Card>
          </Col>


          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Palavra Chave 2" bordered={false}>
              <Form layout="vertical" form={formPalavraChave2} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsBotPalavraChave2 != undefined) {
                  if (dados.mensagemPadraoTechsBotPalavraChave2.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListPalavraChave2);

                    fileListPalavraChave2.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsBotPalavraChave2,
                      imagem: [JSON.stringify(fileListPalavraChave2)],
                      palavraChave: dados.palavraChave2
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      console.log(dados);

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/palavraChave2`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                          setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item
                  name="palavraChave2"
                >
                  <Input
                    placeholder="Palavra Chave 2"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Mensagem" name="mensagemPadraoTechsBotPalavraChave2">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('palavra2')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >
                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListPalavraChave2} setFileList={setFileListPalavraChave2} ></UploadImg>
              </Form>
            </Card>
          </Col>















          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Palavra Chave 3" bordered={false}>
              <Form layout="vertical" form={formPalavraChave3} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsBotPalavraChave3 != undefined) {
                  if (dados.mensagemPadraoTechsBotPalavraChave3.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListPalavraChave3);

                    fileListPalavraChave3.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsBotPalavraChave3,
                      imagem: [JSON.stringify(fileListPalavraChave3)],
                      palavraChave: dados.palavraChave3
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      console.log(dados);

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/palavraChave3`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                          setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item
                  name="palavraChave3"
                >
                  <Input
                    placeholder="Palavra Chave 3"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Mensagem" name="mensagemPadraoTechsBotPalavraChave3">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('palavra3')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >
                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListPalavraChave3} setFileList={setFileListPalavraChave3} ></UploadImg>
              </Form>
            </Card>
          </Col>













          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Palavra Chave 4" bordered={false}>
              <Form layout="vertical" form={formPalavraChave4} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsBotPalavraChave4 != undefined) {
                  if (dados.mensagemPadraoTechsBotPalavraChave4.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListPalavraChave4);

                    fileListPalavraChave4.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsBotPalavraChave4,
                      imagem: [JSON.stringify(fileListPalavraChave4)],
                      palavraChave: dados.palavraChave4
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      console.log(dados);

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/palavraChave4`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                          setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item
                  name="palavraChave4"
                >
                  <Input
                    placeholder="Palavra Chave 4"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Mensagem" name="mensagemPadraoTechsBotPalavraChave4">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('palavra4')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >
                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListPalavraChave4} setFileList={setFileListPalavraChave4} ></UploadImg>
              </Form>
            </Card>
          </Col>



          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
            <Card title="Palavra Chave 5" bordered={false}>
              <Form layout="vertical" form={formPalavraChave5} onFinish={(dados) => {
                console.log(dados);
                if (dados.mensagemPadraoTechsBotPalavraChave5 != undefined) {
                  if (dados.mensagemPadraoTechsBotPalavraChave5.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];
                    console.log(fileListPalavraChave5);

                    fileListPalavraChave5.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemPadraoTechsBotPalavraChave5,
                      imagem: [JSON.stringify(fileListPalavraChave5)],
                      palavraChave: dados.palavraChave5
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      console.log(dados);

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/palavraChave5`, body,
                        // `https://deliverypdv.com.br/v1/sessao/inserirMsg/${idLoja}`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                          setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item
                  name="palavraChave5"
                >
                  <Input
                    placeholder="Palavra Chave 5"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Mensagem" name="mensagemPadraoTechsBotPalavraChave5">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      // opacity: arrayContatos.length == 0 ? 0.5 : 1,
                      // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                    }}
                    // disabled={arrayContatos.length == 0 ? true : false}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('palavra5')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >
                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListPalavraChave5} setFileList={setFileListPalavraChave5} ></UploadImg>
              </Form>
            </Card>
          </Col>









          <Col xs={24} sm={20} md={15} lg={15} xl={15} style={{ marginBottom: "10px" }}>
          <ScheduleEditor selectedBotID={selectedBotID} loading={loading} setLoading={setLoading}></ScheduleEditor>
            <Card title="Mensagem de Ausência Padrão" bordered={false}>
              <Form layout="vertical" form={formMensagemDeAusencia} onFinish={(dados) => {
                if (dados.mensagemDeAusencia != undefined) {
                  if (dados.mensagemDeAusencia.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];

                    fileListMensagemDeAusencia.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemDeAusencia,
                      imagem: [JSON.stringify(fileListMensagemDeAusencia)]
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/mensagemDeAusencia`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                           setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item label="Mensagem de Ausência" name="mensagemDeAusencia">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                    }}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('mensagemDeAusencia')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListMensagemDeAusencia} setFileList={setFileListMensagemDeAusencia} ></UploadImg>
              </Form>
            </Card>


            <Card title="Mensagem de Ausência do Dia" bordered={false}>
              <Form layout="vertical" form={formMensagemDeAusenciaDoDia} onFinish={(dados) => {
                if (dados.mensagemDeAusenciaDoDia != undefined) {
                  if (dados.mensagemDeAusenciaDoDia.trim() === '') {
                    message.error('A mensagem não pode estar vazia');
                  } else {
                    const arrayDeImagens = [];

                    fileListMensagemDeAusenciaDoDia.map((imagem) => {
                      arrayDeImagens.push(JSON.stringify(imagem));
                    });
                    const body = {
                      idBot: selectedBotID,
                      mensagem: dados.mensagemDeAusenciaDoDia,
                      imagem: [JSON.stringify(fileListMensagemDeAusenciaDoDia)]
                    };

                    try {
                      setLoading(true);
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        },
                      };

                      clientePadraoApi.put(
                        `/v1/bot/mensagem/mensagemDeAusenciaDoDia`, body,
                        options
                      ).then((resp) => {
                        console.log(resp.data);
                        if (resp.status == 200) {
                          message.success('Mensagem salva!');
                          setRecarregar(recarregar);
                           setLoading(false);
                        }
                      });

                      console.log(body);

                    } catch (error) {
                      console.error('Erro ao chamar a API:', error.status);
                      if (error.status === 404) {
                        console.error(`${error.status}`);
                        message.error('Erro ao gravar a mensagem!');
                      }
                      setLoading(false);
                    }
                  }
                } else {
                  message.error('A mensagem não pode estar vazia');
                }
              }}>
                <Form.Item label="Mensagem de Ausência do Dia" name="mensagemDeAusenciaDoDia">
                  <TextArea
                    rows={4}
                    onChange={handleTextAreaChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                    }}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                  <Button type="primary" htmlType="button"
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      color: 'white',
                    }}
                    loading={loading}
                    onClick={()=>{limparMensagem('mensagemDeAusenciaDoDia')}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  >

                  </Button>
                </Form.Item>

                <UploadImg fileList={fileListMensagemDeAusenciaDoDia} setFileList={setFileListMensagemDeAusenciaDoDia} ></UploadImg>
              </Form>
            </Card>
          </Col>










     


        </> : ''
        }
      </> : ''



      }


    </Row>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${camuflado});
`;

export default Configuracoes;
