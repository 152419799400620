import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";

import {
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  TeamOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import Home from "./Home.js/Home";
import styled from "styled-components";
import Mensagem from "./mensagens/Mensagem";
import CrudMensagem from "./mensagens/CrudMensagem";
import { Link, useHistory, useParams } from "react-router-dom";
import Sair from "./Sair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faGear,
  faHome,
  faImage,
  faList,
  faRightFromBracket,
  faShop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./TabStyles.css";
import { logout } from "../../Token/actions";
import { useDispatch } from "react-redux";
import Perfil from "./perfil/Perfil";
import VisualizarPerfil from "./perfil/VisualizarPerfil";
import AlterarSenhaScreen from "./perfil/AlterarSenhaScreen";
import PageHeader from "./pageHeader/PageHeader";
import axiosInstance from "../../axiosInterceptor";
import jwtDecode from "jwt-decode";
import Sobre from "./Sobre";
import Configuracoes from "./configuracoes/Configuracoes";
import Servidor from "./perfil/Servidor";
import MensagemAgendada from "./mensagens/MensagemAgendada";
import CrudGrupos from "./grupos/CrudGrupos";
import MensagemAgendadaGrupos from "./grupos/MensagemAgendadaGrupos";
const { TabPane } = Tabs;
const TopTab = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("1");
  const { tab } = useParams();
  const [activeTabPerfil, setActiveTabPerfil] = useState("subTab1");
  const [activeTabMensagem, setActiveTabMensagem] = useState("subTabMensagem1");


  const [activeTabMensagemGrupo, setActiveTabMensagemGrupo] = useState("subTabMensagemGrupo1");

  









  const [tabName, setTabName] = useState("Início");
  const [recarregarDadosHabitualidades, setRecarregarDadosHabitualidades] =
    useState(false);
  const [recarregarDadosClube, setRecarregarDadosClube] = useState(false);

  const [recarregarDadosPerfil, setRecarregarDadosPerfil] = useState(false);
  const [recarregarDadosInicio, setRecarregarDadosInicio] = useState(false);
  const [arrayContatos, setArrayContatos] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [
    carregarUsuarioFormHabitualidade,
    setCarregarUsuarioFormHabitualidade,
  ] = useState(false);
  const [carregarPerfil, setCarregarPerfil] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const accessToken = window.localStorage.getItem("accessToken");

  const [nomeUsuarioLogado,setNomeUsuarioLogado] = useState('');
  const [nomeLoja,setNomeLoja] = useState('');

  const [recarregarConfiguracoes,setRecarregarConfiguracoes] = useState(true);
  const [server, setServer] = useState(window.localStorage.getItem("server"));
  const [recarregarServer, setRecarregarServer] = useState(false);


  useEffect(() => {
    if (!idUser) return;
  
    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!loja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setNomeLoja(resp.data.nome);
        setNomeUsuarioLogado(jwtDecode(accessToken).nome_completo);
      }
    };
    fetchData();
  }, [idUser]);

  useEffect(() => {
    if (tab === "2" || tab === "4") {
      if (tab === "2") {
        setTabName("Habitualidade");
      }
      if (tab === "4") {
        setTabName("Contatos");
      }

      setActiveKey(tab);
    }
   
  }, [tab]);


  const handleRecarregarDadosHabitualidades = () => {
    setRecarregarDadosHabitualidades(!recarregarDadosHabitualidades);
  };
  const handleRecarregarDadosPerfil = () => {
    setRecarregarDadosPerfil(!recarregarDadosPerfil);
  };



  const handleTabChangePerfil = (key) => {

    setActiveTabPerfil(key);
    handleRecarregarDadosPerfil();
    if (key === "subTab2") {
      setCarregarPerfil(!carregarPerfil);
    }
    if (key === "servidor") {
      setRecarregarServer(!recarregarServer);
    }
  };
  const handleTabChangeMensagem = (key) => {
    setActiveTabMensagem(key);
  };
  const handleTabChangeMensagemGrupo = (key) => {
    setActiveTabMensagemGrupo(key);
  };

  


  const handleTabClick = (key) => {
    console.log('key',key);
    if (key === "1") {
      setRecarregarDadosInicio(!recarregarDadosInicio);
      setTabName("Início");
    }

    if (key === "3") {
      setTabName("Conta");
      setRecarregarServer(!recarregarServer);
    }
    if (key === "4") {
      setTabName("Contatos");

    }

  
        
    if (key === "6") {
      setTabName("Configurações");
      setRecarregarConfiguracoes(!recarregarConfiguracoes);
    }

    if (key === "5") {
      setTabName("Sair");
      const confirmed = window.confirm("Deseja sair?");
      if (confirmed) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        history.push("/");
        window.location.reload();
      } else {
        setActiveKey("1");
        setTabName("Início");
      }
    } else {
      setActiveKey(key);
    }
  };

  return (
    <Container>
      <PageHeader tabName={tabName} nomeLoja={nomeLoja} nomeUsuarioLogado={nomeUsuarioLogado}  />
      <Tabs
        activeKey={activeKey}
        tabBarGutter={20}
        centered
        //styled={{ width: "100%" }}
        onTabClick={handleTabClick}
        tabBarStyle={{
          backgroundColor: "black",
          color: "white",
          activeTab: "#43e97b",
          margin: 0,
          position: "fixed",
          zIndex: 99,
          width: "100%",
          bottom: 0,
        }}
      >
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faHome}
                style={{ width: "25px", height: "25px" }}
              />
              Painel
            </span>
          }
          key="1"
        >
          <Home recarregarDados={recarregarDadosInicio}></Home>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                 icon={faList}
                style={{ width: "25px", height: "25px" }}
              />
              Contatos
            </span>
          }
          key="contatos"
        >
          <Tabs
            activeKey={activeTabMensagem}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeMensagem}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="LISTA" key="subTabMensagem1">
              <CrudMensagem arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudMensagem>
            </TabPane>
            {/*<TabPane tab="MENSAGEM" key="subTabMensagem2">
              <Mensagem
                arrayContatos={arrayContatos}
                pagination={pagination}
              ></Mensagem>
            </TabPane>*/}

            <TabPane tab="MENSAGEM AGENDADA" key="mensagemAgendada">
              <MensagemAgendada
                arrayContatos={arrayContatos}
                pagination={pagination}
              ></MensagemAgendada>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                 icon={faList}
                style={{ width: "25px", height: "25px" }}
              />
              Grupos
            </span>
          }
          key="grupos"
        >
          <Tabs
            activeKey={activeTabMensagemGrupo}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeMensagemGrupo}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="LISTA" key="subTabMensagemGrupo1">
              <CrudGrupos arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudGrupos>
            </TabPane>
            <TabPane tab="MENSAGEM AGENDADA GRUPO" key="mensagemAgendadaGrupo">
              <MensagemAgendadaGrupos
                arrayContatos={arrayContatos}
                pagination={pagination}
              ></MensagemAgendadaGrupos>
            </TabPane>
          </Tabs>
        </TabPane>















        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faGear}
                style={{ width: "25px", height: "25px" }}
              />
              Configurações
            </span>
          }
          key="6"
        >
          <Configuracoes recarregarConfiguracoes = {recarregarConfiguracoes}></Configuracoes>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ width: "25px", height: "25px" }}
              />
              Conta
            </span>
          }
          key="3"
        >
          <Tabs
            activeKey={activeTabPerfil}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangePerfil}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="VISUALIZAR" key="subTab1">
              <VisualizarPerfil
                onTabChange={handleTabChangePerfil}
                recarregarDados={recarregarDadosPerfil}
              ></VisualizarPerfil>
            </TabPane>
            <TabPane tab="REGISTRO" key="subTab2">
              <Perfil
                onTabChange={handleTabChangePerfil}
                setarReload={handleRecarregarDadosPerfil}
                recarregarFormPerfil={carregarPerfil}
              ></Perfil>
            </TabPane>
            <TabPane tab="ALTERAR SENHA" key="subTab3">
              <AlterarSenhaScreen
                onTabChange={handleTabChangePerfil}
              ></AlterarSenhaScreen>
            </TabPane>
            <TabPane tab="SERVIDOR" key="servidor">
              <Servidor
                recarregarServer={recarregarServer}
                server={server}
                setServer={setServer}
              ></Servidor>
            </TabPane>
          </Tabs>
        </TabPane>
      
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{ width: "25px", height: "25px" }}
              />
              Sobre
            </span>
          }
          key="2"
        >
          <Sobre></Sobre>
        </TabPane>

        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ width: "25px", height: "25px" }}
              />
              Sair
            </span>
          }
          key="5"
        ></TabPane>

 
      </Tabs>

    </Container>
  );
};
const Container = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const TabsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1; /* Garante que as abas fiquem acima do conteúdo */
`;

const CentralizedText = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: 10px;
  width:'100%';
`;
export default TopTab;
